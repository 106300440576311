import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import axios from 'axios';
import {AsperaLoginPage, Link, tracking, Button, AsperaForm, InlineNotification, SubManager, language, AsperaError, openNewTab} from '@aspera-ui/starter';
import '@aspera-ui/starter/styles/aspera-core-global.scss';
import '@aspera-ui/starter/styles/aspera-global.scss';
import './styles.scss';
import englishFull from './en-us';
import {availableLanguages} from './constants';

class LoginPage extends React.Component {
  state = {
    loading: true,
    error: false,
    inlineNotificationError: null,
    initialLoadComplete: false,
    view: null,
  };

  model = {
    login: '',
    password: ''
  };

  subManager = new SubManager();
  basicLoginData = {};
  basicHeaderBrandingOptions = {};

  getButton = (button) => {
    return (
      <div className="button-wrapper" key={button.id}>
        <Button kind={button.kind} href={button.link} onClick={() => console.log(`${button.button_name} clicked`)}>
          {button.button_name}
        </Button>
        {button.link_div === true ? (
          <div className="auth-provider-instructions">
            {language.getStyled('loginPage.alreadyHaveAccount', undefined, [button.instruction_details?.link])}
          </div>
        ) : (
          <div className="auth-provider-instructions">{button.instruction_details?.name}</div>
        )}
      </div>
    );
  }

  getFormLink = (forgotLink) => {
    return(
      <div className="form-action-button">
        <Link href={forgotLink} id="problems-link">Forgot password?</Link>
      </div>
    );
  }

  get errorProps() {
    switch (this.props.view) {
      case 'unauthorized_error':
        return {
          type: '403',
          title: language.get('errorPage.unauthorizedTitle'),
          body: language.get('errorPage.unauthorizedSubtitle'),
        };
      case 'invalid_scope_error':
        return {
          type: '0',
          title: language.get('errorPage.invalidScopeTitle'),
          body: language.get('errorPage.invalidScopeSubtitle', [this.props.scope]),
        };
      case 'missing_parameter_error':
        return {
          type: '0',
          title: language.get('errorPage.missingParameterTitle'),
          body: language.getStyled('errorPage.missingParameterSubtitle', [this.props.missing_parameter], ["http://tools.ietf.org/html/rfc6749"]),
        };
      case 'invalid_token_error':
        return {
          type: '403',
          title: language.get('errorPage.invalidTokenTitle'),
          body: language.get('errorPage.invalidTokenSubtitle'),
        };
      case 'unknown_response_type_error':
        return {
          type: '0',
          title: language.get('errorPage.unknownResponseTypeTitle'),
          body: language.get('errorPage.unknownResponseTypeSubtitle'),
        };
      case 'unregistered_client_error':
        return {
          type: '403',
          title: language.get('errorPage.unregisteredClientTitle'),
          body: language.get('errorPage.unregisteredClientSubtitle'),
        };
      case 'unregistered_redirect_uri_error':
        return {
          type: '403',
          title: language.get('errorPage.unregisteredRedirectUriTitle'),
          body: language.get('errorPage.unregisteredRedirectUriSubtitle'),
        };
      case 'unauthorized_client_error':
        return {
          type: '403',
          title: language.get('errorPage.unauthorizedClientTitle'),
          body: language.get('errorPage.unauthorizedClientSubtitle', ["http://tools.ietf.org/html/rfc6749"]),
        };
        case 'invalid_token_current_user_error':
        return {
          type: '403',
          title: language.get('errorPage.invalidTokenTitle'),
          body: language.get('errorPage.invalidTokenCurrentUserSubtitle', [this.props.email]),
          links: [
            {
              name: language.get('errorPage.signInUnderDiffAccount'),
              onClick: () => {
                window.location.href = this.props.destroy_session_path_link;
              },
            },
          ],
        };
        case 'invalid_scope_current_user_error':
          return {
            type: '403',
            title: language.get('errorPage.invalidScopeTitle'),
            body: language.get('errorPage.invalidScopeCurrentUserSubtitle', [this.props.email]),
            links: [
              {
                name: language.get('errorPage.signInUnderDiffAccount'),
                onClick: () => {
                  window.location.href = this.props.destroy_session_path_link;
                },
              },
            ],
         };
      case 'missing_parameter_current_user_error':
        return {
          type: '0',
          title: language.get('errorPage.missingParameterTitle'),
          body: language.getStyled('errorPage.missingParameterCurrentUserSubtitle', [this.props.missing_parameter, this.props.email], ["http://tools.ietf.org/html/rfc6749"]),
          links: [
            {
              name: language.get('errorPage.signInUnderDiffAccount'),
              onClick: () => {
                window.location.href = this.props.destroy_session_path_link;
              },
            },
          ],
        };
      case 'unauthorized_client_current_user_error':
        return {
          type: '403',
          title: language.get('errorPage.unauthorizedClientTitle'),
          body: language.getStyled('errorPage.unauthorizedClientCurrentUserSubtitle', [this.props.email], ["http://tools.ietf.org/html/rfc6749"]),
          links: [
            {
              name: language.get('errorPage.signInUnderDiffAccount'),
              onClick: () => {
                window.location.href = this.props.destroy_session_path_link;
              },
            },
          ],
        };
        case 'unknown_response_type_current_user_error':
          return {
            type: '0',
            title: language.get('errorPage.unknownResponseTypeTitle'),
            body: language.get('errorPage.unknownResponseTypeCurrentUserSubtitle', [this.props.email]),
            links: [
              {
                name: language.get('errorPage.signInUnderDiffAccount'),
                onClick: () => {
                  window.location.href = this.props.destroy_session_path_link;
                },
              },
            ],
          };
        case 'unregistered_client_current_user_error':
          return {
            type: '403',
            title: language.get('errorPage.unregisteredClientTitle'),
            body: language.get('errorPage.unregisteredClientCurrentUserSubtitle', [this.props.email]),
            links: [
              {
                name: language.get('errorPage.signInUnderDiffAccount'),
                onClick: () => {
                  window.location.href = this.props.destroy_session_path_link;
                },
              },
            ],
          };
        case 'unregistered_redirect_uri_current_user_error':
          return {
            type: '403',
            title: language.get('errorPage.unregisteredRedirectUriTitle'),
            body: language.get('errorPage.unregisteredRedirectUriCurrentUserSubtitle', [this.props.email]),
            links: [
              {
                name: language.get('errorPage.signInUnderDiffAccount'),
                onClick: () => {
                  window.location.href = this.props.destroy_session_path_link;
                },
              },
            ],
          };
      case 'invite_only_error':
        return {
          type: '403',
          title: language.get('errorPage.inviteOnlyTitle'),
          body: language.get('errorPage.inviteOnlySubtitle'),
        };
      case 'invite_only_error_deactivated1':
        return {
          type: '403',
          title: language.get('errorPage.invalidIbmUserErrorTitle'),
          body: language.get('errorPage.invalidIbmUserErrorSubtitleDeactivated1Subtitle'),
        };
      case 'invite_only_error_deactivated2':
        return {
          type: '403',
          title: language.get('errorPage.invalidIbmUserErrorTitle'),
          body: language.get('errorPage.invalidIbmUserErrorSubtitleDeactivated2Subtitle'),
        };
      case 'account_deactivated_error':
        return {
          type: '403',
          title: language.get('errorPage.accountDeactivatedTitle'),
          body: language.get('errorPage.accountDeactivatedSubtitle'),
        };
      case 'invalid_short_link_error':
        return {
          type: '0',
          title: language.get('errorPage.invalidShortLinkTitle'),
          body: language.get('errorPage.invalidShortLinkSubtitle'),
        };
      case 'unverified_email_error':
        return {
          type: '0',
          title: language.get('errorPage.unverifiedEmailTitle'),
          links: [
            {
              name: 'Log in with Google to verify',
              onClick: () => {
                window.location.href = this.props.link;
              },
            },
          ],
        };
      case 'organization_not_found_error':
        return {
          type: '404',
          title: language.get('errorPage.organizationNotFoundTitle'),
          body: language.get('errorPage.organizationNotFoundSubtitle'),
        };
      case 'locked_error':
        return {
          type: '403',
          title: language.get('errorPage.lockedTitle'),
          body: language.get('errorPage.lockedSubtitle'),
        };
      case 'invalid_ibm_user_error':
        return {
          type: '403',
          title: language.get('errorPage.invalidIbmUserErrorTitle'),
          body: language.get('errorPage.invalidIbmUserErrorSubtitle', [this.props.email]),
          links: [
            {
              name: language.get('errorPage.loginScreenText'),
              onClick: () => {
                openNewTab(this.props.logout_link, true)
                window.location.href = this.props.link;
              },
            },
          ],
        };
      case 'invalid_ibm_user_error_deactivated1':
        return {
          type: '403',
          title: language.get('errorPage.invalidIbmUserErrorTitle'),
          body: language.get('errorPage.invalidIbmUserErrorSubtitleDeactivated1Subtitle'),
        };
      case 'invalid_ibm_user_error_deactivated2':
        return {
          type: '403',
          title: language.get('errorPage.invalidIbmUserErrorTitle'),
          body: language.get('errorPage.invalidIbmUserErrorSubtitleDeactivated2Subtitle'),
        };
      case 'invalid_icp_user_error':
        return {
          type: '0',
          title: language.get('errorPage.invalidIcpUserErrorTitle'),
          body: language.get('errorPage.invalidIcpUserErrorSubtitle'),
          links: [
            {
              name: language.get('errorPage.loginScreenText'),
              onClick: () => {
                window.location.href = this.props.login_link;
              },
            },
          ],
        };
      case 'invalid_icp_user_oauth_error':
        return {
          type: '403',
          title: language.get('errorPage.invalidIcpUserOauthTitle'),
          body: language.get('errorPage.invalidIcpUserOauthSubtitle', [this.props.email]),
          links: [
            {
              name: language.get('errorPage.loginScreenText'),
              onClick: () => {
                window.location.href = this.props.login_link;
              },
            },
            {
              name: language.get('errorPage.logoutText'),
              onClick: () => {
                window.location.href = this.props.link;
              },
            },
          ],
        };
      case 'invalid_ibm_id_response_error':
        return {
          type: '0',
          title: language.get('errorPage.invalidIbmIdResponseTitle'),
          body: language.get('errorPage.invalidIbmIdResponseSubTitle'),
        };
      case 'invalid_icp_id_response_error':
        return {
          type: '0',
          title: language.get('errorPage.invalidIcpIdResponseTitle'),
          body: language.get('errorPage.invalidIcpIdResponseSubTitle'),
        };
      case 'invalid_ibm_id_user_error':
        return {
          type: '403',
          title: language.get('errorPage.invalidIbmIdUserTitle'),
          body: language.get('errorPage.invalidIbmIdUserSubtitle'),
          links: [
            {
              name: language.get('errorPage.loginScreenText'),
              onClick: () => {
                window.location.href = this.props.link;
              },
            },
          ],
        };
      case 'insecure_redirect_uri_error':
        return {
          type: '403',
          title: language.get('errorPage.insecureRedirectTitle'),
          body: language.get('errorPage.insecureRedirectSubtitle'),
          links: [
            {
              name: language.get('errorPage.continue'),
              onClick: () => {
                window.location.href = this.props.link;
              },
            },
            {
              name: language.get('errorPage.exitText'),
              onClick: () => {
                window.location.href = 'https://ibmaspera.com/'
              },
            },
          ],
        };
    }
  }

  setupPage() {
    switch (this.props.view) {
      case 'unauthorized_error':
      case 'invalid_scope_error':
      case 'missing_parameter_error':
      case 'invalid_token_error':
      case 'unknown_response_type_error':
      case 'unregistered_client_error':
      case 'unregistered_redirect_uri_error':
      case 'unauthorized_client_error':
      case 'invalid_token_current_user_error':
      case 'invalid_scope_current_user_error':
      case 'missing_parameter_current_user_error':
      case 'unauthorized_client_current_user_error':
      case 'unknown_response_type_current_user_error':
      case 'unregistered_client_current_user_error':
      case 'unregistered_redirect_uri_current_user_error':
      case 'invite_only_error':
      case 'invite_only_error_deactivated1':
      case 'invite_only_error_deactivated2':
      case 'account_deactivated_error':
      case 'invalid_short_link_error':
      case 'unverified_email_error':
      case 'organization_not_found_error':
      case 'locked_error':
      case 'invalid_ibm_user_error':
      case 'invalid_ibm_user_error_deactivated1':
      case 'invalid_ibm_user_error_deactivated2':
      case 'invalid_icp_user_error':
      case 'invalid_icp_user_oauth_error':
      case 'invalid_ibm_id_response_error':
      case 'invalid_icp_id_response_error':
      case 'invalid_ibm_id_user_error':
      case 'insecure_redirect_uri_error':
        this.setState({error: true, loading: false});
        break;
      default:
        this.setState({loading: false});
        break;
    }
  }

  get useWorkspaceSetting() {
    return this.props.loginData.workspaceBoolean && this.props.loginData.workspaceLoginCustOverrideBoolean;
  }

  componentWillUnmount() {
    this.subManager.unsubscribeAll();
  }

  componentDidMount() {
    language.initDictionary(englishFull, availableLanguages);
    tracking.currentTheme = tracking.systemTheme;
    this.setState({initialLoadComplete: true});

    this.basicLoginData = {
      layout: this.useWorkspaceSetting ? this.props.loginData.workspaceLoginLayout : this.props.loginData.orgLoginlayout,
      announcementOneTitle: this.useWorkspaceSetting ? this.props.loginData.workspaceAnnouncementOneTitle : this.props.loginData.orgAnnouncementOneTitle,
      announcementOneMessage: this.useWorkspaceSetting ? this.props.loginData.workspaceAnnouncementOneMessage : this.props.loginData.orgAnnouncementOneMessage,
      announcementTwoTitle: this.useWorkspaceSetting ? this.props.loginData.workspaceAnnouncementTwoTitle : this.props.loginData.orgAnnouncementTwoTitle,
      announcementTwoMessage: this.useWorkspaceSetting ? this.props.loginData.workspaceAnnouncementTwoMessage : this.props.loginData.orgAnnouncementTwoMessage,
      loginPageBackgroundPosition: this.useWorkspaceSetting ? this.props.loginData.workspaceLoginPageBackgroundPosition : this.props.loginData.organizationLoginPageBackgroundPosition,
      loginPageLogo: {initialData: this.useWorkspaceSetting ? this.props.headerData.workspaceLoginImageUrl : this.props.headerData.orgLoginImageUrl},
      loginPageBackground: {initialData: this.useWorkspaceSetting ? this.props.headerData.workspaceLoginBackgroundImageUrl : this.props.headerData.orgLoginBackgroundImageUrl},
    };

    this.basicHeaderBrandingOptions = {
      displayName: language.get('loginPage.asperaOnCloud'),
      imageUrl:  this.props.headerData.orgAppLogoImageUrl,
      customText: this.props.headerData.organizationAppLogoText
    };

    this.setupPage();

    if (this.props.headerData.customTheme) {
      tracking.currentCustomTheme = this.props.headerData.customTheme;
    };

    this.setState({
      loading: false,
    });

    this.submit = (event) => {
      this.setState({ inlineNotificationError: null });
      const formData = {
        login: this.model.login,
        password: this.model.password
      };

      const params = this.props.loginData.loginPathRequestParams
      const combinedParams = { ...formData, ...params };
      let csrfToken = document.querySelector('meta[name="csrf-token"]')
      csrfToken = csrfToken ? csrfToken.content : null;

      axios.post(this.props.loginData.loginPathUrl, combinedParams, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRF-Token': csrfToken
        }
      })
      .then(response => {
        const redirectUri = response.data.redirectUri
        if (redirectUri) {
          return window.location.replace(redirectUri);
        } else {
          this.setState({ inlineNotificationError: null});
        }
      })
      .catch(error => {
        this.setState({ inlineNotificationError: { title: error.response.data.error }});
      })
      .finally(() => {
        this.model.login = '';
        this.model.password = '';
      });
    };

    this.formDetails = {
      id: 'loginForm',
      class: 'form-field',
      submitText: this.props.loginData.localLoginBtnText,
      model: this.model,
      onSubmit: this.submit,
      items: [
        {type: 'text', pointer: 'login', label: 'Email', disableSpellcheck: true, required: true, placeholder: 'Email'},
        {type: 'password', pointer: 'password', label: 'Password', disableSpellcheck: true, required: true, placeholder: 'Password'},
      ]
    };

    const finalCall = () => {
      this.setState({
        loading: false,
      });
    }

    this.subManager.setSubscription(language.utilizeLanguage().subscribe({
      next: () => {
        finalCall();
      },
      error: error => {
        new AsperaError('LanguageService: unable to setup language service', error);
        finalCall();
      },
    }));
  }

  render() {
    const {loading, error, inlineNotificationError, initialLoadComplete} = this.state;
    const showBackdoorPageButton = this.props.loginData.showBackdoorPageButton
    const primarybuttonData = this.props.loginData.primaryAuthProvider
    const secondarybuttonData = this.props.loginData.secondaryAuthProviders || []
    const localAuthFormData = this.props.loginData.localAuthEnabled
    const inlineNotificationError1 = this.props.loginData.inlineNotificationError
    const formItem = <AsperaForm formDetails={this.formDetails} className='aspera-form'/>;
    const forgotLink = this.props.loginData.forgotLink
    if (!initialLoadComplete) {
      return null;
    }

    return (
      <Router>
        <AsperaLoginPage
          headerData={this.basicHeaderBrandingOptions}
          productName={language.get('loginPage.asperaOnCloud')}
          pageHeader={(this.useWorkspaceSetting ? this.props.headerData.workspaceName : this.props.headerData.orgName) || this.props.headerData.orgName}
          loginData={this.basicLoginData}
          externalLoading={loading}
          externalError={error}
          externalErrorProps={this.errorProps}
          footerString={language.get('loginPage.copyright')}
        >
          {inlineNotificationError && (<InlineNotification role="alert" className='inline-notification' kind="error" title={inlineNotificationError.title} subtitle={<div dangerouslySetInnerHTML={{__html: inlineNotificationError.subtitle}} />}hideCloseButton={true}/>)}
          {inlineNotificationError1 && (<InlineNotification role="alert" className='inline-notification' kind="error" title={inlineNotificationError1.title} subtitle={<div dangerouslySetInnerHTML={{__html: inlineNotificationError1.subtitle}} />}hideCloseButton={true}/>)}
          {showBackdoorPageButton && this.getButton(showBackdoorPageButton)}
          {localAuthFormData && formItem}
          {localAuthFormData && this.getFormLink(forgotLink)}
          {primarybuttonData && this.getButton(primarybuttonData)}
          {secondarybuttonData.map(button => this.getButton(button))}
        </AsperaLoginPage>
      </Router>
    );
  }
}

export default LoginPage;
